/* eslint-disable */
import { IUser } from './user.model'

export enum CompanyRole {
  employee = 'employee',
  evalClient = 'evalclient',
}
export interface IUserSubsegment {
  user?: IUser
  invitationToken?: string
  invitationEmail?: string
  companyRoles?: CompanyRole[]
}

export interface ICompany {
  _id?: string
  name?: string
  email?: string
  metadata: {
    phone?: string
    address?: {
      street?: string
      zip?: string
      city?: string
      country?: string
    }
    logo?: string | null | undefined
  }
  allowShowLogo?: boolean,
  allowShowName?: boolean,
  activated?: boolean,
  validCertificate?: boolean,
  createdAt?: Date
  updatedAt?: Date
  users?: IUserSubsegment[]
}

export class Company implements ICompany {
  constructor (
    public _id?: string,
    public name?: string,
    public email?: string,
    public metadata: {
      phone?: string
      address?: {
        street?: string
        zip?: string
        city?: string
        country?: string
      }
      logo?: string | null | undefined
    } = {},
    public activated?: boolean,
    public validCertificate?: boolean,
    public createdAt?: Date,
    public updatedAt?: Date,
    public users?: IUserSubsegment[],
  ) {
    this.metadata = {}
    this.metadata.address = { street: '' }
    return this
  }
}
