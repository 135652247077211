var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-profile","fluid":"","tag":"section"}},[_c('scroll-indicator'),(_vm.breadcrumbItems.length)?_c('v-breadcrumbs',{attrs:{"divider":"/","items":_vm.breadcrumbItems}}):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[((_vm.company && _vm.company.activated) || (_vm.company && _vm.userIsSystemAdmin))?_c('base-material-card',{attrs:{"color":"indigo","icon":"mdi-city"},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"mobile-card-heading"},[_vm._v(" "+_vm._s(_vm.$t('company-profile'))+" ")])]},proxy:true}],null,false,4087360631)},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('form',[_c('v-row',{staticClass:"mx-auto",attrs:{"justify":"space-around","align":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('image-upload',{attrs:{"image":_vm.image,"image-content-type":_vm.imageContentType,"disabled":!_vm.userIsEvalClient && !_vm.userIsSystemAdmin},on:{"imageLoaded":_vm.setImage}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":_vm.$t('name') + '*',"prepend-icon":"mdi-account","validate-on-blur":"","readonly":!_vm.userIsEvalClient && !_vm.userIsSystemAdmin},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"email","name":_vm.$t('email')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":_vm.$t('email') + '*',"prepend-icon":"mdi-email","autocomplete":"off","validate-on-blur":"","readonly":!_vm.userIsEvalClient && !_vm.userIsSystemAdmin},model:{value:(_vm.company.email),callback:function ($$v) {_vm.$set(_vm.company, "email", $$v)},expression:"company.email"}})]}}],null,true)}),_c('v-text-field',{attrs:{"color":"secondary","label":_vm.$t('phone'),"prepend-icon":"mdi-phone","validate-on-blur":"","readonly":!_vm.userIsEvalClient && !_vm.userIsSystemAdmin},model:{value:(_vm.company.metadata.phone),callback:function ($$v) {_vm.$set(_vm.company.metadata, "phone", $$v)},expression:"company.metadata.phone"}})],1)],1),_c('v-row',{staticClass:"mx-auto",attrs:{"justify":"space-around"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"color":"secondary","label":_vm.$t('uid'),"validate-on-blur":"","prepend-icon":"mdi-badge-account","readonly":!_vm.userIsEvalClient && !_vm.userIsSystemAdmin},model:{value:(_vm.company.uid),callback:function ($$v) {_vm.$set(_vm.company, "uid", $$v)},expression:"company.uid"}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('street')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"secondary","error-messages":errors,"label":_vm.$t('street') + '*',"validate-on-blur":"","prepend-icon":"mdi-post","readonly":!_vm.userIsEvalClient && !_vm.userIsSystemAdmin},model:{value:(_vm.company.metadata.address.street),callback:function ($$v) {_vm.$set(_vm.company.metadata.address, "street", $$v)},expression:"company.metadata.address.street"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('zipcode')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":_vm.$t('zipcode') + '*',"validate-on-blur":"","readonly":!_vm.userIsEvalClient && !_vm.userIsSystemAdmin},model:{value:(_vm.company.metadata.address.zip),callback:function ($$v) {_vm.$set(_vm.company.metadata.address, "zip", $$v)},expression:"company.metadata.address.zip"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('city')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"color":"secondary","error-messages":errors,"label":_vm.$t('city') + '*',"validate-on-blur":"","prepend-icon":"mdi-city","readonly":!_vm.userIsEvalClient && !_vm.userIsSystemAdmin},model:{value:(_vm.company.metadata.address.city),callback:function ($$v) {_vm.$set(_vm.company.metadata.address, "city", $$v)},expression:"company.metadata.address.city"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('country')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"items":_vm.countries,"color":"secondary","label":_vm.$t('country') + '*',"validate-on-blur":"","readonly":!_vm.userIsEvalClient && !_vm.userIsSystemAdmin},model:{value:(_vm.company.metadata.address.country),callback:function ($$v) {_vm.$set(_vm.company.metadata.address, "country", $$v)},expression:"company.metadata.address.country"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$t('allow-coworkers-to-show-on-profile'))+" ")]),_c('v-row',{staticClass:"ml-1"},[_c('v-switch',{attrs:{"label":_vm.$t('company-logo'),"color":"success"},model:{value:(_vm.company.allowShowLogo),callback:function ($$v) {_vm.$set(_vm.company, "allowShowLogo", $$v)},expression:"company.allowShowLogo"}}),_c('v-switch',{staticStyle:{"margin-left":"30px"},attrs:{"label":_vm.$t('company-name'),"color":"success"},model:{value:(_vm.company.allowShowName),callback:function ($$v) {_vm.$set(_vm.company, "allowShowName", $$v)},expression:"company.allowShowName"}})],1)],1)],1),_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[(_vm.userIsEvalClient)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"opacity":".5"}},on),[_vm._v(" "+_vm._s(_vm.$t('leave-company'))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.admin-cant-leave-company')))])]):(_vm.userIsInCompany)?_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.clickLeave}},[_vm._v(" "+_vm._s(_vm.$t('leave-company'))+" ")]):_vm._e(),(_vm.userIsEvalClient || _vm.userIsSystemAdmin)?_c('v-btn',{attrs:{"color":"success","disabled":_vm.dataChanged || !valid},on:{"click":_vm.clickSave}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_vm._e()],1)],1)],1)]}}],null,false,2360013200)})],1):(!_vm.company && _vm.create)?_c('company-wizard',{on:{"wizardcanceled":function($event){_vm.create = false}}}):(_vm.company && !_vm.company.activated)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"md":"6"}},[_c('p',{staticStyle:{"font-size":"1.3em"}},[_vm._v(" "+_vm._s(_vm.$t('dialog.your-company-has-been-created-check-your-emails'))+" ")]),_c('p',{staticStyle:{"font-size":"1.3em","margin-top":"45px"}},[_vm._v(" "+_vm._s(_vm.$t('dialog.if-you-havent-received-an-email-yet'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"indigo","disabled":_vm.activationLinkSentAgain},on:{"click":function($event){return _vm.clickSendCompanyActivationLinkAgain(_vm.company)}}},on),[_vm._v(" "+_vm._s(_vm.$t('resend-activation-email'))+" "),_c('v-icon',{staticClass:"ml-3"},[_vm._v(" mdi-send ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.send-activation-again')))])]),(_vm.activationLinkSentAgain)?_c('div',[_c('p',{staticStyle:{"font-size":"1.3em","margin-top":"45px"}},[_vm._v(" "+_vm._s(_vm.$t('dialog.if-this-did-not-work'))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.clickLeave(_vm.company)}}},on),[_vm._v(" "+_vm._s(_vm.$t('leave-company'))+" "),_c('v-icon',{staticClass:"ml-3"},[_vm._v(" mdi-send ")])],1)]}}],null,false,90780318)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.send-activation-again')))])])],1):_vm._e()],1)],1):_c('v-row',{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.create = true}}},[_vm._v(" "+_vm._s(_vm.$t('dialog.create-company'))+" ")])],1)],1)],1),(_vm.company && _vm.userIsSystemAdmin)?_c('coworker-table'):_vm._e(),(_vm.company && _vm.company.activated && _vm.userIsSystemAdmin)?_c('reports-table',{attrs:{"disable-create-reports":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }