import axios from 'axios'
import store from '../../store'
// eslint-disable-next-line
import { ICompany } from '../model/company.model'
// eslint-disable-next-line
import { IUser, User } from '../model/user.model'

class CompanyService {
  createCompany (company: ICompany) {
    return axios.post('/api/company', company).then(result => {
      if (result.status === 200 && result.data) {
        this.getCompany(store)
      }
    })
  }

  createCompanyAsAdmin (company: ICompany) {
    return axios.post('/api/companyasadmin', company).then(result => {
      if (result.status === 200 && result.data) {
        this.getCompany(store)
      }
    })
  }

  getUsersCompany (id: string) {
    return axios.get(`/api/userscompany/${id}`)
  }

  // following is not using an id because the backend throws back the company related to the user
  getCompany (store: any, id: string | null = null) {
    store.dispatch('fetchCompany', id)
  }

  inviteUser (companyId: string, invitation: any) {
    return axios.post(`/api/company/${companyId}/inviteUsers`, [invitation])
  }

  inviteUserAgain (invitationToken: string) {
    return axios.post('/api/company/inviteUserAgain', { invitationToken })
  }

  updateCompany (store: any, company: ICompany) {
    return axios.put(`/api/company/${company._id}`, company)
  }

  deleteCompany (id: string) {
    return axios.delete(`/api/company/${id}`).catch(e => {
      console.error('deleteCompany() failed', e)
    })
  }

  acceptCompanyInvitation (token: string) {
    return axios.get(`/api/acceptCompanyInvitation/${token}`)
  }

  leaveCompany () {
    return axios.post('/api/company/leave').then(() => {
      this.getCompany(store)
    })
  }

  sendCompanyActivationLinkAgain (company: ICompany): Promise<any> {
    return axios.get(`/api/companyActivateLink/${company._id}`)
  }
}

const companyService = new CompanyService()
export default companyService
