















































































































































































































































































































































import { COUNTRIES } from '@/shared/constants'
import { ICompany, Company, CompanyRole, IUserSubsegment } from '@/shared/model/company.model'
import _ from 'lodash'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import companyService from '@/shared/services/companyService'
import { UserRole } from '@/shared/model/user.model'

export default Vue.extend({
  components: {
    CompanyWizard: () => import('@/views/app/company/CompanyWizard.vue'),
    CoworkerTable: () => import('@/views/app/company/CoworkerTable.vue'),
    ImageUpload: () => import('@/views/app/_components/ImageUpload.vue'),
    ScrollIndicator: () => import('@/views/app/_components/ScrollIndicator.vue'),
    ReportsTable: () => import('@/views/app/report/ReportsTable.vue'),
  },
  data: () => ({
    image: null as any | string,
    imageContentType: null as any | string,
    companyFromDB: new Company() as ICompany,
    countries: COUNTRIES,
    userRoles: [CompanyRole.employee, CompanyRole.evalClient],

    breadcrumbItems: [] as any[],

    create: false,
    activationLinkSentAgain: false,
  }),
  computed: {
    ...mapGetters({
      account: 'account',
      company: 'company',
    }),
    dataChanged () {
      return _.isEqual(this.companyFromDB, this.company)
    },
    userIsInCompany: function () {
      if (this.company) {
        return !!this.company.users.find((x: IUserSubsegment) => {
          if (x.user && x.companyRoles && this.account) {
            return x.user._id === this.account._id
          }
        })
      }
      return false
    },
    userIsEvalClient: function () {
      if (this.company) {
        const user = this.company.users.find((x: IUserSubsegment) => {
          if (x.user && x.companyRoles && this.account) {
            return x.user._id === this.account._id
          }
        })
        if (user && user.companyRoles.includes(CompanyRole.evalClient)) {
          return true
        }
      }
      return false
    },
    userIsSystemAdmin: function () {
      if (this.account && this.account.roles.includes(UserRole.admin)) {
        return true
      }
      return false
    },
  },
  watch: {
    company: function () {
      if (this.company && this.company.metadata && this.company.metadata.logo) {
        this.image = `/api/file/${this.company.metadata.logo}`
        this.imageContentType = null
      }
      if (!this.companyFromDB && this.company) {
        this.companyFromDB = _.cloneDeep(this.company)
      }
      const id = this.$route.params.id
      if (id && this.company) {
        this.breadcrumbItems = [
          {
            text: this.$t('companies'),
            to: '/app/companies',
            exact: true,
          },
          {
            text: `${this.company.name}`,
          },
        ]
      }
    },
    $route: function () {
      this.breadcrumbItems = []
      this.image = ''
      const id = this.$route.params.id
      companyService.getCompany(this.$store, id)
    },
  },
  mounted: function () {
    const id = this.$route.params.id
    companyService.getCompany(this.$store, id)
  },
  methods: {
    async clickSave () {
      companyService
        .updateCompany(this.$store, this.company)
        .then(result => {
          const id = this.$route.params.id
          this.$toast(this.$t('dialog.save-company-success').toString())
          companyService.getCompany(this.$store, id)
        })
        .catch(e => {
          this.$toast.error(this.$t('dialog.save-company-failed').toString())
        })
    },
    async clickLeave () {
      this.$confirm(`${this.$t('dialog.do-you-really-want-to-leave-the-company')}`, {
        color: 'error',
        title: this.$t('leave-company').toString(),
      }).then(async confirmed => {
        if (confirmed) {
          await companyService.leaveCompany()
        }
      })
    },
    setImage: function (blobContent: any) {
      if (blobContent && blobContent.image) {
        this.image = blobContent.image
        if (blobContent.imageContentType) {
          this.imageContentType = blobContent.imageContentType
        } else {
          this.imageContentType = null
        }
        this.image = blobContent.image
        if (this.company.metadata) {
          this.company.metadata.logo = blobContent.image
        }
      }
    },
    clickSendCompanyActivationLinkAgain (company: ICompany) {
      companyService.sendCompanyActivationLinkAgain(company).then(result => {
        this.activationLinkSentAgain = true
      })
    },
  },
})
